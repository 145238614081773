<template>
	<div>
		<div class="searchw">
			<Form :label-width="65" inline>
				<FormItem label="指南标题">
					<Input v-model="posdata.title" @on-keyup.13="search" maxlength="150" style="width: 260px;"></Input>
				</FormItem>
				<FormItem :label-width="45" label="状态">
					<Select transfer v-model="posdata.status" :class="[posdata.status==-1?'no-sel':'']" @on-change="search" style="width: 110px;">
            <Option :value="-1">不限</Option>
						<Option v-for="(item,index) in status" :value="index" :key="index">{{item}}</Option>
					</Select>
				</FormItem>
				<Button icon="md-search" @click="search" @keyup.13="search">搜索</Button>
				<Button style="float: right;" type="info" icon="md-add" @click="opentags('/editnews',{id:0})">新增文章</Button>
			</Form>
		</div>
		<div class="table-list">
			<Table size="small" :columns="columns" :data="data.data" :loading="table_loading">
				<template slot-scope="row" slot="litpic">
					<img class="litpic" v-viewer :src="row.row.litpic?row.row.litpic:ApiUrl+'/static/appimg/defaultimg.png'" style="display: block;width: 120px; height: 80px; cursor: zoom-in;" />
				</template>
        <template slot-scope="row" slot="status">
          <i-switch size="large" :true-value="1" :false-value="2" v-model="data.data[row.index].status" @on-change="changeStatus($event,row.row.id)">
            <span slot="open">展示</span>
            <span slot="close">不展</span>
          </i-switch>
        </template>
				<template slot-scope="row" slot="active">
					<ButtonGroup>
						<Button size="small" type="info" @click="edit(row.row.id)">编辑</Button>
						<Button size="small" type="error" @click="del(row.row.id)">删除</Button>
					</ButtonGroup>
				</template>
			</Table>
		</div>
		<div class="footer-m">
			<Page class-name="pagew page-r" :total="data.total" :current="data.current_page" :page-size="data.per_page" :page-size-opts="[15,30,50]" show-sizer show-total @on-change="changePage" @on-page-size-change="changePageSize"/>
		</div>

	</div>
</template>

<script>
	export default {
		name: "NewsList",
		data(){
			return{
				columns:[
					{title:'指南ID',key:'id',minWidth:60},
          {title:'缩略图',slot:'litpic',minWidth:130},
					{title:'指南标题',key:'title',minWidth:300,ellipsis:true},
					{title:'指南状态',slot:'status',minWidth:100},
					{title:'点击量',key:'click',minWidth:80},
					{title:'发布时间',key:'add_time',minWidth:130},
					{title:'操作',slot:'active',width:120,align:'center'}
				],
				data:[],
				status:{1:'展示',2:'不展示'},
        posdata:{title:'', status:-1, page:1, page_size:15},
        table_loading:false
			}
		},
		created() {
			this.init();
		},
		methods:{
			init(){
				var _this = this;
				this.table_loading=true;
				this.requestApi('/adm/news_list.html',this.posdata).then(function (res) {
          _this.table_loading=false;
					if (res.status==1){
						_this.data = res.data;
					}
				})
			},
      /**
       * 编辑
       */
			edit(id){
				this.opentags('/editnews',{'id':id});
			},
      /**
       * 删除
       */
			del(id){
				var _this = this;
				this.confirm('确定删除吗？',function (){
          _this.requestApi('/adm/delnews.html',{'id':id}).then(function (res) {
            if(res.status==1){
              _this.tipSuccess(res.msg);
              _this.init();
            }else{
              _this.alertError(res.msg)
            }
          })
        })
			},
      /**
       * 更新新手指南状态
       */
      changeStatus:function (status,id){
        var _this = this;
        this.requestApi('/adm/upnews_status.html',{'id':id,'status':status}).then(function (res) {
          if(res.status==0){
            _this.init();
            _this.alertError(res.msg);
          }
        })
      },
      /**
       * 搜索
       */
      search:function (){
        this.posdata.page = 1;
        this.init();
      },
      /**
       * 翻页
       */
			changePage(page){
				this.posdata.page = page;
				this.init();
			},
      /**
       * 切换页码数量
       */
			changePageSize(size){
				this.posdata.page = 1;
				this.posdata.page_size = size;
				this.init();
			}
		}
	}
</script>

<style lang="less"></style>
